import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#b53f3f', // Replace '#yourColor' with your desired primary color (e.g., '#3f51b5').
    },
  },
});

export default theme;
