import React from 'react';
import { Container, Typography } from '@mui/material';

const Terms = () => {
  return (
    <div style={{ marginLeft: '20px', margintTop: '20px'}}>
      <h2>Terms and Conditions</h2>
      <p>Please read these Terms and Conditions ("Terms") carefully before using the www.theai.au website (the "Service") operated by The AI ("us", "we", or "our").</p>
      <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service.</p>
      <h3>1. Purpose of the Service</h3>
      <p>The AI provides a service that allows users to submit questions related to Oracle Service Cloud documentation. The Service processes and analyses the provided context and Oracle Service Cloud documentation to generate responses.</p>
      <h3>2. Eligibility</h3>
      <p>Our Service is intended for Oracle Service Cloud consultants and administrators, or people who work with Oracle B2C Service products. There are no specific age restrictions for using our Service.</p>
      <h3>3. User-Generated Content</h3>
      <p>As our Service stores user-generated content in the form of system logs, users should be aware that their inputs may be retained for a certain period. We reserve the right to refuse or remove any content that violates our policies or applicable laws. For more information on how we handle user data and privacy, please refer to our Privacy Policy.</p>
      <h3>4. Privacy Policy</h3>
      <p>Please refer to our <a href="/privacy">Privacy Policy</a> section for information on how we handle user data and privacy.</p>
      <h3>5. Intellectual Property and Copyright Infringement</h3>
      <p>Intellectual property refers to the legal rights that creators have over their creations, which may include copyrights, trademarks, or patents. If you believe that your intellectual property rights have been infringed upon through our Service, please contact us with a detailed description of the alleged infringement, and we will take appropriate action.</p>
      <h3>6. Disclaimer</h3>
      <p>The AI is not affiliated with or endorsed by Oracle. Our Service is provided on an "as is" basis, and we do not offer any warranties or guarantees for the accuracy, reliability, or completeness of the information provided. Users are advised to consult the official Oracle website and documentation for verified information. We shall not be held responsible or liable for any errors, omissions, or inaccuracies in the information provided through our Service.</p>
      <h3>7. Modifications to the Terms</h3>
      <p>We reserve the right to modify these Terms at any time. We will not provide any notice of such changes, as we do not store user information. Your continued use of the Service after any changes have been made constitutes your acceptance of the updated Terms.</p>
      <h3>8. Governing Law and Dispute Resolution</h3>
      <p>These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which you are located. Any disputes arising out of or in connection with the Service shall be resolved through negotiation, mediation, or arbitration, as appropriate.</p>
      <h3>9. Limitation of Liability</h3>
      <p>In no event shall The AI, its owners, employees, or affiliates be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed its essential purpose.</p>
      <h3>10. Indemnification</h3>
      <p>You agree to defend, indemnify, and hold harmless The AI, its owners, employees, and affiliates from and against any and all claims, damages, obligations, losses, liabilities, costs, and expenses (including but not limited to attorney's fees), arising from or related to: (a) your use of and access to the Service; (b) your violation of any term of these Terms; (c) your violation of any third-party right, including without limitation any copyright, property, or privacy right; or (d) any claim that your use of the Service caused damage to a third party. This indemnification obligation will survive these Terms and your use of the Service.</p>
      <h3>11. Termination</h3>
      <p>We reserve the right to terminate or restrict your access to the Service at any time, without notice, for any reason or for no reason, at our sole discretion.</p>
      <h3>12. Contact Us</h3>
      <p>If you have any questions or concerns about these Terms or our Service, please feel free to <a href="/contactus">contact us</a>.</p>

      {/* Continue with the rest of the headings and paragraphs, following the same pattern */}
      {/* Continue with the rest of the headings and paragraphs, following the same pattern */}
    </div>
  );
};

export default Terms;
