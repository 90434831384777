import Terms from './Terms';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Privacy from './Privacy';
import ContactUs from './ContactUs'
import React, { useState } from 'react';
import ScrollToTop from './ScrollToTop';
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  AppBar,
  Toolbar,
  Paper,
  ThemeProvider,
  CircularProgress,
  getAlertTitleUtilityClass,
} from '@mui/material';
//import ThumbUp from '@mui/icons-material/ThumbUp';
//import ThumbDown from '@mui/icons-material/ThumbDown';
import {HiOutlineThumbUp} from 'react-icons/hi';
import {HiOutlineThumbDown} from 'react-icons/hi';
import axios from 'axios';
import theme from './theme';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Routes } from 'react-router';
import { Snackbar, Alert } from '@mui/material';


const MainContent = ({ setUserQuestion, userInput, handleInputChange, handleSubmit, isLoading, userQuestion, response, showThumbIcons, setShowThumbIcons }) => {

  const characterCount = userInput.length;
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [userFeedback, setUserFeedback] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);



  const handleFeedbackSubmit = async (feedback) => {
    try {
      setSnackbarOpen(true);
      setFeedbackDialogOpen(false);
      setShowThumbIcons(false); // Hide the thumb icons
      await axios.post('https://www.theai.au/api/send_feedback', {
        question: userQuestion,
        response: response,
        feedback: feedback,
        user_feedback: userFeedback
      });
      // Display the notification banner
      //alert('');
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setUserFeedback('');
    }
  };


  const handleFeedbackDialogClose = () => {
    setFeedbackDialogOpen(false);
  };

  return (
    <Container maxWidth="sm" sx={{ flexGrow: 1 }}>
      <Box sx={{ my: 4 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          {/* ... existing main content */}
          {/* ... */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="h5" component="h2" gutterBottom>
              Questions about Oracle B2C Service?
            </Typography>
          </Box>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            label="Your message"
            value={userInput}
            onChange={handleInputChange}
            inputProps={{ maxLength: 200 }}
          />
          <Typography sx={{ mt: 1 }} variant="body2">{characterCount}/200</Typography>
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isLoading || userInput.trim() === ''}>
              Send
            </Button>
          </Box>
          {userQuestion && (
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6">User:</Typography>
              <Typography>{userQuestion}</Typography>
            </Box>
          )}
          {isLoading ? (
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            response && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6">The AI:</Typography>
                {response.split('\n').map((line, index) => (
                  <Typography key={index}>{line}</Typography>
                ))}
                {showThumbIcons && (
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                  <IconButton color="success" onClick={() => handleFeedbackSubmit('positive')}>
                    <HiOutlineThumbUp/>
                  </IconButton>
                  <IconButton color="error" onClick={() => setFeedbackDialogOpen(true)}>
                    <HiOutlineThumbDown/>
                  </IconButton>

                </Box>
                )}
                <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={6000}
                  onClose={() => setSnackbarOpen(false)}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <Alert onClose={() => setSnackbarOpen(false)} severity="success">
                    Feedback submitted successfully!
                  </Alert>
                </Snackbar>
              </Box>
            )
          )}
        </Paper>
      </Box>
      <Dialog open={feedbackDialogOpen} onClose={handleFeedbackDialogClose}>
        <DialogTitle>Provide your feedback</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please explain the issue with the response.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="feedback"
            label="Your feedback"
            type="text"
            fullWidth
            value={userFeedback}
            onChange={(e) => setUserFeedback(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFeedbackDialogClose}>Cancel</Button>
          <Button onClick={() => handleFeedbackSubmit('negative')} color="primary">Send</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const App = () => {
  const [userInput, setUserInput] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userQuestion, setUserQuestion] = useState('');
  const [showThumbIcons, setShowThumbIcons] = useState(true);


  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setResponse('');
    setUserQuestion(userInput);
    setShowThumbIcons(true); // Show the thumb icons again

    try {
      const result = await axios.post('https://www.theai.au/ask', { question: userInput });
      setResponse(result.data.response);
    } catch (error) {
      console.error('Error:', error);
      setResponse('An error occurred while processing your request.');
    } finally {
      setIsLoading(false);
      setUserInput(''); // Clear the user input after the request

    }
  };


  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <AppBar position="static">
            <Toolbar>
              <Link to="/" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center'  }}>
              <img src="/logo.jpg" alt="Logo" style={{ width: '4rem', height: '4rem', marginRight: '8px' }} />
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  The AI
                </Typography>
              </Link>
            </Toolbar>
          </AppBar>
          <Box sx={{ flexGrow: 1 }}>
            <Routes>
              <Route path="/" element={<MainContent setUserQuestion={setUserQuestion} userInput={userInput} handleInputChange={handleInputChange} handleSubmit={handleSubmit} isLoading={isLoading} userQuestion={userQuestion} response={response} showThumbIcons={showThumbIcons} setShowThumbIcons={setShowThumbIcons} />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/contactus" element={<ContactUs />} />
            </Routes>
          </Box>
          <Box sx={{ py: 2, bgcolor: 'primary.main', color: 'white' }}>
            <Container maxWidth="lg">
              <Typography variant="body2" align="center">
                &copy; {new Date().getFullYear()} The AI. All rights reserved.
                <Link to="/terms" color="inherit" style={{ marginLeft: 16 , color: 'white'}}>
                  Terms and Conditions
                </Link>
                <Link to="/privacy" color="inherit" style={{ marginLeft: 16 , color: 'white'}}>
                  Privacy Policy
                </Link>
                <Link to="/contactus" color="inherit" style={{ marginLeft: 16 , color: 'white'}}>
                  Contact Me
                </Link>
              </Typography>
            </Container>
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
};

export default App;