import React, { useState } from 'react';
import axios from 'axios';
import './ContactUs.css';

const ContactForm = ({ handleChange, handleSubmit, formData }) => (
  <form onSubmit={handleSubmit}>
    <div className="form-group">
      <input
        type="text"
        name="name"
        placeholder="Your name"
        value={formData.name}
        onChange={handleChange}
        className="form-control"
        required
      />
    </div>
    <div className="form-group">
      <input
        type="email"
        name="email"
        placeholder="Your email"
        value={formData.email}
        onChange={handleChange}
        className="form-control"
        required
      />
    </div>
    <div className="form-group">
      <textarea
        name="message"
        placeholder="Your message"
        value={formData.message}
        onChange={handleChange}
        className="form-control"
        rows="5"
        required
      ></textarea>
    </div>
    <button type="submit" className="btn btn-danger btn-block">
      Send Message
    </button>
  </form>
);

const ContactUs = () => {
  const initialFormData = { name: '', email: '', message: '' };
  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    try {
      const response = await axios.post('https://www.theai.au/api/send_email', formData);
      setSuccessMessage(response.data.message);
      setFormData(initialFormData); // Reset form data
    } catch (error) {
      setErrorMessage('Error sending email. Please try again later.');
      console.error('Error sending email:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <h2 className="text-center my-5">Contact Me</h2>
      {successMessage && <div className="alert alert-success">{successMessage}</div>}
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <ContactForm handleChange={handleChange} handleSubmit={handleSubmit} formData={formData} />
          {isLoading && (
            <div className="spinner-border text-danger" role="status">
              <span className="sr-only"></span>
            </div>
          )}
          {errorMessage && <p className="text-danger">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;