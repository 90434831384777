import React from 'react';
import { Container, Typography } from '@mui/material';

const Privacy = () => {
    return (
        <div style={{ marginLeft: '20px', marginTop: '20px' }}>
            <h2>Privacy Policy</h2>
            <p>At The AI, we respect your privacy and are committed to protecting any personal information you may provide while using our Service. This Privacy Policy explains our practices for collecting, using, and disclosing that information.</p>
            <h3>1. Information Collection and Use</h3>
            <p>As our Service does not require users to create an account or provide any personal information, we do not collect or store any personal data from our users. However, user inputs and feedback may be stored as system logs to maintain and improve the quality of our Service.</p>
            <h3>2. Cookies</h3>
            <p>We do not use cookies or similar tracking technologies to collect information about your use of our Service.</p>
            <h3>3. Changes to This Privacy Policy</h3>
            <p>We reserve the right to modify this Privacy Policy at any time. Any changes will be effective immediately upon posting, and your continued use of the Service after any changes have been made constitutes your acceptance of the updated Privacy Policy.</p>
            <h3>4. Review of Third-Party Privacy Policy</h3>
            <p>Our Service utilises OpenAI APIs to generate responses. As a result, some user data may be processed by OpenAI in accordance with their Privacy Policy. We encourage you to review OpenAI's Privacy Policy, which can be found <a href="https://openai.com/policies/api-data-usage-policies"> here</a>, to understand how your data may be processed when using our Service.</p>
        </div>
  );
};

export default Privacy;
